<script lang="ts" setup>
import { ref, computed, watch, onMounted, getCurrentInstance } from "vue";
import { patchPartyAddressDetails, postPartyAddressDetails } from "@/services/api/location.api";
import { getPartyRoles, getInstallationGroups, getConstructionSourceTypes, getEuPrecisionTypes, getMeasureGroups, severityCode } from "@/services/api/masterdata.api";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import useDetailEdit from "@/composables/detailEdit.js";
import { navigateToLocation } from "@/composables/general.js";
import _ from "lodash";
import { emptyGuid } from "@/composables/miscellaneous.js";
import { getAddressesOverview, getLocationPartyDetails } from "@/services/api/location.api";
import { v4 as uuidv4 } from "uuid";
import { useCacheStore } from "@/stores/cache";
import DateInput from "@/components/general/DateInput.vue";
import ContactRoleSelection from "@/components/party/ContactRoleSelection.vue";

const {
  setMode,
  setReadonlyMode,
  setEditMode,
  setEditTLMode,
  setDetails,
  hasDetails,
  selectNewIndex,
  selectedData,
  updateSelectedDetail,
  resetSelectedDetail,
  isReadOnly,
  isAddingMode,
  isAddingMasterMode,
  isAddingDetailMode,
  isEditDataMode,
  isEditTLMode,
  allowEdit,
  allowEditTL,
  addNewDetailMode,
  detailTabs,
  modDateFormatted,
  checkDateValid,
} = useDetailEdit();

setInteractionMode("eager");

const props = defineProps({
  id: String,
  locationReference: String,
  partyReference: String,
  addModus: {
    type: Boolean,
    default: false,
  },
});

const parties = ref([]);
const addresses = ref([]);
const partyRoles = ref([]);
const installationGroups = ref([]);
const buildingGroups = ref([]);
const euPrecisionGroups = ref([]);
const measureGroups = ref([]);

const dataMustBeRetrieved = ref(false);

const partiesLoaded = ref(false);
const addressesLoaded = ref(false);
const partyRolesLoaded = ref(false);
const installationGroupsLoaded = ref(false);
const buildingGroupsLoaded = ref(false);
const euPrecisionGroupsLoaded = ref(false);
const measureGroupsLoaded = ref(false);
const isSaving = ref(false);

const selectedTabIndex = ref(undefined);
const mainDataLoaded = ref(false);
const cacheStore = useCacheStore();

const masterDataLoaded = computed(
  () =>
    partiesLoaded.value && addressesLoaded.value && partyRolesLoaded.value && installationGroupsLoaded.value && buildingGroupsLoaded.value && euPrecisionGroupsLoaded.value && measureGroupsLoaded.value
);
const allDataLoaded = computed(() => (isAddingMasterMode.value && masterDataLoaded.value) || (!isAddingMasterMode.value && mainDataLoaded.value));

const contactList = computed(() => {
  if (selectedData.value.contacts) {
    const list = [...selectedData.value.contacts];
    list.sort((a, b) => a.partyRoleDescription.localeCompare(b.partyRoleDescription));
    return list;
  } else {
    return [];
  }
});

watch(
  () => [props.id, props.addModus],
  () => {
    setMode(props.id, props.addModus);
    if (!isAddingMasterMode.value) {
      dataMustBeRetrieved.value = true;
      fetchData();
    } else {
      //initialiseer array voor contacts
      //console.log("PartyAddressDetails, watch addModus", selectedData.value);
      selectedData.value = { contacts: [] };
    }
  },
  { immediate: true }
);

watch(
  () => masterDataLoaded.value,
  () => {
    if (!isAddingMode.value) {
      fetchData();
    }
  }
);

watch(
  () => selectedTabIndex.value,
  () => {
    selectNewIndex(selectedTabIndex.value);
  }
);

onMounted(() => {
  loadParties();
  loadAddresses();
  loadPartyRoles();
  loadInstallationGroups();
  loadBuildingGroups();
  loadEuPrecisionGroups();
  loadMeasureGroups();
});

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  console.log("PartyAddressDetails, fetchData", props.id);
  if (dataMustBeRetrieved.value && masterDataLoaded.value) {
    const response = await getLocationPartyDetails(props.locationReference, props.id);
    if (response && !response.error) {
      console.log("PartyAddressDetails, fetchData response ", response);
      if (response.data.length > 0) {
        //geef all contacten een unique id
        response.data.forEach((element) => {
          if (element.contacts && element.contacts.length > 0) {
            element.contacts.forEach((contact) => {
              contact.keyId = uuidv4();
            });
          }
        });
        selectedTabIndex.value = setDetails(response.data);
        mainDataLoaded.value = true;
      }
    } else {
      proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
    }
    dataMustBeRetrieved.value = false;
  }
}

const obs = ref(null);

async function saveDetail() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    let data = {};
    if (isEditTLMode.value) {
      data = { valFrom: selectedData.value.valFrom, valTill: selectedData.value.valTill, modDescr: selectedData.value.modDescr };
    } else {
      data = JSON.parse(JSON.stringify(selectedData.value));
      //datums mogen niet geupdate worden
      data.valFrom = null;
      data.valTill = null;
      if (!data.contacts) {
        data.contacts = [];
      }
    }
    const result = await patchPartyAddressDetails(props.locationReference, selectedData.value.detailId, data);
    if (!result.error) {
      updateSelectedDetail();
      if (isEditTLMode.value) {
        // refresh de data i.v.m. gewijzigde tijdlijn.
        fetchData();
      }
      setReadonlyMode();
      proxy.$toaster.success("Gegevens van de koppeling zijn successvol gewijzigd.");
    } else {
      proxy.$toaster.error(result.error);
    }
    isSaving.value = false;
  }
}

function addNewDetail() {
  if (selectedData.value) {
    addNewDetailMode();
  } else {
    proxy.$toaster.warning("Selecteer eerst een versie s.v.p..");
  }
}

async function saveNewDetail() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    let data = {};
    data = JSON.parse(JSON.stringify(selectedData.value));
    data.reference = props.locationReference;
    if (!data.contacts) {
      data.contacts = [];
    }

    const response = await postPartyAddressDetails(props.locationReference, data);

    if (!response.error) {
      proxy.$toaster.success("Gegevens van de koppeling zijn successvol toegevoegd.");
      if (isAddingMasterMode.value) {
        navigateToLocation(props.locationReference);
      } else {
        setReadonlyMode();
        fetchData();
      }
    } else {
      proxy.$toaster.error(response.error);
    }
  }
  isSaving.value = false;
}

async function onCancelAdd() {
  if (isAddingMasterMode.value) {
    proxy.$toaster.warning(`Partij-adres koppeling toevoegen geannuleerd.`);
    navigateToLocation(props.locationReference);
  } else {
    proxy.$toaster.warning(`Partij-adres koppeling versie toevoegen geannuleerd.`);
    setReadonlyMode();
    resetSelectedDetail();
  }
}

async function onCancel() {
  proxy.$toaster.warning(`bewerken geannuleerd.`);
  selectNewIndex(selectedTabIndex.value);
  requestAnimationFrame(() => {
    obs.value.reset();
  });
  setReadonlyMode();
}

async function loadParties() {
  console.log("PartyAddressDetails, loadParties ");
  const response = await cacheStore.parties();
  if (response.success) {
    parties.value = _.map(_.sortBy(response.list, "name"), (option) => {
      return {
        reference: option.reference,
        name: option.name,
      };
    });
    partiesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de partijen is mislukt! " + response.error);
  }
}

async function loadAddresses() {
  console.log("PartyAddressDetails, loadAddresses ");
  const response = await getAddressesOverview(props.locationReference);
  console.log("PartyAddressDetails, loadAddresses response ", response);
  if (response && !response.error) {
    addresses.value = _.map(_.sortBy(response.data.data, "descr"), (option) => {
      return {
        id: option.masterId,
        description: `${option.ref} (${option.descr})`,
      };
    });
    console.log("PartyAddressDetails, loadAddresses  ", addresses.value);
    addressesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de adressen is mislukt! " + response.error);
  }
}

async function loadPartyRoles() {
  console.log("PartyAddressDetails, loadPartyRoles ");
  const response = await getPartyRoles();
  if (response && !response.error) {
    partyRoles.value = _.sortBy([...response.data.keyDescList], "prio");
    console.log("PartyAddressDetails, loadPartyRoles ", partyRoles.value);
    partyRolesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de rollen is mislukt! " + response.error);
  }
}

async function loadInstallationGroups() {
  console.log("PartyAddressDetails, loadInstallationGroups ");
  const response = await getInstallationGroups();
  if (response.severity <= severityCode.warning) {
    installationGroups.value = [...response.data.installationGroups];
    console.log("PartyAddressDetails, loadInstallationGroups ", installationGroups.value);
    installationGroupsLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de installatie groepen is mislukt! " + response.error);
  }
}

async function loadBuildingGroups() {
  console.log("PartyAddressDetails, loadBuildingGroups ");
  const response = await getConstructionSourceTypes();
  if (response && !response.error) {
    buildingGroups.value = _.sortBy([...response.data.keyDescList], "description");
    console.log("PartyAddressDetails, loadBuildingGroups ", buildingGroups.value);
    buildingGroupsLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de construction gegevens groepen is mislukt! " + response.error);
  }
}

async function loadEuPrecisionGroups() {
  console.log("PartyAddressDetails, loadEuPrecisionGroups ");
  const response = await getEuPrecisionTypes();
  if (response && !response.error) {
    euPrecisionGroups.value = [...response.data.keyDescList];
    console.log("PartyAddressDetails, loadEuPrecisionGroups ", euPrecisionGroups.value);
    euPrecisionGroupsLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de energy precisie groepen is mislukt! " + response.error);
  }
}

async function loadMeasureGroups() {
  console.log("PartyAddressDetails, loadMeasureGroups ");
  const response = await getMeasureGroups();
  if (response && !response.error) {
    console.log("PartyAddressDetails, loadMeasureGroups ", response);
    measureGroups.value = _.sortBy([...response.data.appliedGroups], "description");
    measureGroupsLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de maatregelen groepen is mislukt! " + response.error);
  }
}

function onAddContact(contact) {
  console.log("PartyAddressDetails, onAddContact ", contact);
  if (!selectedData.value.contacts) {
    selectedData.value.contacts = [];
  }
  selectedData.value.contacts.push({
    keyId: uuidv4(),
    id: emptyGuid,
    partyRoleKey: contact.roleKey,
    partyRoleDescription: contact.roleDescription,
    userId: contact.userId,
    userDescription: contact.userDescription,
  });
  console.log("PartyAddressDetails, onAddContact ", selectedData.value.contacts);
}

function delContact(keyId) {
  console.log("PartyAddressDetails, delContact ", keyId);
  const index = selectedData.value.contacts.findIndex((item) => {
    return item.keyId == keyId;
  });
  selectedData.value.contacts.splice(index, 1);
}
</script>

<template>
  <v-container fluid pa-0>
    <header class="app-welcome-message d-flex">
      <h2 v-if="!isAddingMasterMode && !isAddingDetailMode">Details van koppeling met partij</h2>
      <h2 v-if="isAddingMasterMode">Toevoegen van koppeling met partij</h2>
      <h2 v-if="isAddingDetailMode">Toevoegen van koppeling met partij versie</h2>
      <h2 :class="{ loadingDots: !allDataLoaded }"></h2>
    </header>
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row align="center">
            <v-col v-if="hasDetails" cols="auto"> Tijdlijn: </v-col>
            <v-col>
              <v-btn-toggle v-model="selectedTabIndex" group mandatory color="blue accent-3">
                <v-btn class="v-application elevation-2" v-for="(item, index) in detailTabs()" :key="index" :value="index" :disabled="!isReadOnly"> {{ item }} </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-container>
        <ValidationObserver ref="obs">
          <v-form>
            <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
              <v-container class="boxed" fluid :class="{ noBorders: !isEditTLMode }">
                <header>Geldigheid</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Geldig vanaf" rules="date_between|required" v-slot="{ errors }" :mode="checkDateValid">
                      <date-input
                        v-model="selectedData.valFrom"
                        persistent-placeholder
                        label="Geldig vanaf"
                        :errors="errors"
                        :class="{ noBorders: !allowEditTL }"
                        :outlined="allowEditTL"
                        :readonly="!allowEditTL"
                        rules="required"
                      ></date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Geldig tot" :rules="`date_between|date_after:${selectedData.valFrom}`" v-slot="{ errors }" :mode="checkDateValid">
                      <date-input
                        v-model="selectedData.valTill"
                        persistent-placeholder
                        label="Geldig tot"
                        :errors="errors"
                        :class="{ noBorders: !allowEditTL }"
                        :outlined="allowEditTL"
                        :readonly="!allowEditTL"
                      ></date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed" :class="{ noBorders: !isEditDataMode && !isEditTLMode }">
                <header>Aanpassing</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Aanpassings omschrijving" rules="required" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        persistent-placeholder
                        label="Omschrijving"
                        v-model="selectedData.modDescr"
                        :error-messages="errors"
                        :class="{ noBorders: !allowEdit && !allowEditTL }"
                        :outlined="allowEdit || allowEditTL"
                        :readonly="!allowEdit && !allowEditTL"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row v-if="!isAddingMasterMode && !isAddingDetailMode">
                  <v-col>
                    <v-text-field hide-details="auto" label="Door" v-model="selectedData.modBy" readonly class="noBorders"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="!isAddingMasterMode && !isAddingDetailMode">
                  <v-col>
                    <v-text-field hide-details="auto" label="Datum" v-model="modDateFormatted" readonly class="noBorders"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed" fluid :class="{ noBorders: !isEditTLMode }">
                <header>Primaire koppeling</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Party" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Partij"
                        persistent-placeholder
                        :items="parties"
                        item-text="name"
                        item-value="reference"
                        v-model="selectedData.partyReference"
                        :error-messages="errors"
                        :outlined="isAddingMasterMode"
                        :class="{ noBorders: !isAddingMasterMode }"
                        :readonly="!isAddingMasterMode"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Address" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Adres"
                        persistent-placeholder
                        :items="addresses"
                        item-text="description"
                        item-value="id"
                        v-model="selectedData.addressableObjectId"
                        :error-messages="errors"
                        :outlined="isAddingMasterMode"
                        :class="{ noBorders: !isAddingMasterMode }"
                        :readonly="!isAddingMasterMode"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed" fluid :class="{ noBorders: !isEditTLMode }">
                <header>Toegangsniveau</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Rol" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Rol"
                        persistent-placeholder
                        :items="partyRoles"
                        item-text="description"
                        item-value="key"
                        v-model="selectedData.partyRole"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="EuPrecisionType" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Energiedata"
                        persistent-placeholder
                        :items="euPrecisionGroups"
                        item-text="description"
                        item-value="key"
                        v-model="selectedData.euPrecisionType"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="EuPowerOfAttorneyType" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Volmacht"
                        persistent-placeholder
                        :items="euPrecisionGroups"
                        item-text="description"
                        item-value="key"
                        v-model="selectedData.euPowerOfAttorneyType"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="boxed" fluid :class="{ noBorders: !isEditTLMode }">
                <header>Secondaire koppelingen</header>

                <v-row>
                  <v-col>
                    <ValidationProvider name="Installatiegroep" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Installatiegroep"
                        persistent-placeholder
                        :items="installationGroups"
                        item-text="ref"
                        item-value="id"
                        v-model="selectedData.installationGroupId"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Bron bouwkundige gegevens" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Bron bouwkundige gegevens"
                        persistent-placeholder
                        :items="buildingGroups"
                        item-text="description"
                        item-value="key"
                        v-model="selectedData.constructionSourceTypeKey"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Maatregelengroep" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        label="Maatregelengroep"
                        persistent-placeholder
                        :items="measureGroups"
                        item-text="reference"
                        item-value="id"
                        v-model="selectedData.ecmGroupId"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="boxed">
                <header>Contacten</header>

                <v-row v-for="item in contactList" v-bind:key="item.keyId">
                  <v-col cols="4">{{ item.partyRoleDescription }}</v-col>
                  <v-col>{{ item.userDescription }}</v-col>
                  <v-col v-if="item.valid==false"> (niet actief) </v-col>
                  <v-col cols="auto">
                    <v-btn v-if="allowEdit" @click="delContact(item.keyId)" icon small plain>
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider v-if="allowEdit" class="my-3"></v-divider>
                <contact-role-selection v-if="allowEdit" @on-add-contact="onAddContact"></contact-role-selection>
              </v-container>
            </masonry>

            <details-buttons
              :addMode="isAddingMode"
              :editMode="!isReadOnly"
              :allowSave="!isSaving"
              @edit="setEditMode"
              @edit-tl="setEditTLMode"
              @on-cancel="onCancel"
              @add-new-detail="addNewDetail"
              @on-cancel-add="onCancelAdd()"
              @save-detail="saveDetail"
              @save-new-detail="saveNewDetail"
            />
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>
